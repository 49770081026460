import React from "react";
import Icons from "./icons";
import PagePicker from "../../Fields/PagePicker/PagePicker";
import PagesContext from "./PagesContext";

function Icon({ node }) {
  if (node.root) {
    return <Icons.Home />;
  }

  if (node.children.length === 0) {
    return <Icons.ChildFree />;
  }

  return (
    <>
      <Icons.Closed className="icon-closed" />
      <Icons.Opened className="icon-opened" />
    </>
  );
}

function Input({ value, onChange }) {
  return (
    <div className="form-group">
      <label className="control-label">Title</label>
      <input
        className="form-control"
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

function Modal({ node, pagesUrl, onConfirm, onClose }) {
  const pages = React.useContext(PagesContext);
  const page = pages[node.value];

  return (
    <div className="admin-modal">
      <div className="modal-cnt">
        <Input
          value={node.title || ""}
          onChange={(newTitle) => {
            onConfirm(node.value, newTitle);
          }}
        />

        <PagePicker
          label="Page"
          page={{
            id: node.value,
            name: page?.name || "",
            slug: page?.slug || "",
          }}
          search_url={pagesUrl}
          onSelect={(newValue) => {
            onConfirm(newValue.id, node.title);
          }}
        />

        <button
          className="btn btn-default btn btn-lg btn-success"
          type="button"
          onClick={() => onClose()}
        >
          Close
        </button>
      </div>

      <div className="modal-overlay" onClick={() => onClose()} />
    </div>
  );
}

function Node({ node, level, pagesUrl, onUpdate }) {
  const [open, setOpen] = React.useState(false);
  const pages = React.useContext(PagesContext);

  const page = pages[node.value];

  return (
    <details
      open
      className="node"
      style={{
        paddingLeft: `calc(${level} * 1rem)`,
      }}
    >
      <summary>
        <Icon node={node} />
        {!node.root && (
          <div>
            {node.title} {page?.slug && `(${page?.slug})`}
          </div>
        )}

        {!node.root && (
          <button
            type="button"
            className="action"
            onClick={() => {
              setOpen(true);
            }}
          >
            <Icons.Edit />
          </button>
        )}

        {!node.root && (
          <button
            type="button"
            className="action"
            onClick={() => {
              let message =
                "Are you sure you want to delete this node and its children?";

              if (page) {
                message = `Are you sure you want to delete '${page?.name}' and its children?`;
              }

              if (confirm(message)) {
                onUpdate({ type: "deleteNode", data: { nodeId: node.id } });
              }
            }}
          >
            <Icons.Delete />
          </button>
        )}

        <button
          type="button"
          className="action"
          onClick={() => {
            onUpdate({ type: "addNode", data: { parentId: node.id } });
          }}
        >
          <Icons.Add />
        </button>
      </summary>

      {open && (
        <Modal
          node={node}
          pagesUrl={pagesUrl}
          onConfirm={(newPageId, newTitle) => {
            onUpdate({
              type: "editNode",
              data: { nodeId: node.id, value: newPageId, title: newTitle },
            });
          }}
          onClose={() => setOpen(false)}
        />
      )}

      <div>
        {node.children.map((n) => (
          <Node
            key={n.id}
            node={n}
            level={level + 1}
            pagesUrl={pagesUrl}
            onUpdate={onUpdate}
          />
        ))}
      </div>
    </details>
  );
}

export default Node;
